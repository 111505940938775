<template>
  <v-container class="fill-height bg pa-0" fluid>
    <!--  Navbar  -->
    <u-base-nav />

    <!--  Content  -->
    <v-container
      class="
        fill-height
        d-flex
        justify-center
        align-content-center
        gray-5--text
      "
    >
      <v-row align-items="center" justify="center">
        <v-col align-self="center" cols="auto">
          <v-icon color="gray-5" size="128">fas fa-eye-slash</v-icon>
        </v-col>
        <v-col
          :class="{ 'text-center': $vuetify.breakpoint.smAndDown }"
          cols="auto"
        >
          <h1 class="text-404">#404</h1>
          <p class="space-mono font-weight-bold not-found-text">
            Page Not Found
          </p>
        </v-col>
      </v-row>

      <!--  Action Button  -->
      <v-row justify="center">
        <u-button
          class="text-capitalize secondary--text font-weight-bold"
          color="background"
          to="/"
        >
          Click here to go Home
        </u-button>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
import { UBaseNav, UButton } from "@/components/base";

export default {
  name: "Error404Page",
  components: { UButton, UBaseNav },
};
</script>

<style scoped>
.text-404 {
  font-size: 5rem;
  line-height: 110%;
}

.not-found-text {
  font-size: xxx-large;
}
</style>
